import { useState } from 'react';
import Enllac from 'components/3cat/Enllac';
import Icona from 'components/3cat/ui/Icona';
import { useProducte } from 'hooks/3cat/useProducte';
import Image from 'next/image';
import { dataResources } from 'utils/constants';
import clsx from 'clsx';
import styles from './peu.module.scss';

const { basePath } = dataResources;
const imageLoader = ({ src }) => {
    return `${src}`;
};

export default function Peu() {
    const { isWebviewActive } = useProducte();
    const [openDropdownCorpo, setOpenDropdownCorpo] = useState(false);
    const [openDropdownContact, setOpenDropdownContact] = useState(false);
    const [openDropdownLlengua, setOpenDropdownLlengua] = useState(false);

    const stylesDropdownCorpo = clsx({
        [styles.open]: openDropdownCorpo
    });
    const stylesDropdownContact = clsx({
        [styles.open]: openDropdownContact
    });
    const stylesDropdownLlengua = clsx({
        [styles.open]: openDropdownLlengua
    });

    return isWebviewActive ? (
        <></>
    ) : (
        <footer data-testid='peu' aria-label='Peu de la CCMA' className={styles.footer_wrapper}>
            <div className={styles.footer_pre}>
                <div className={styles.footer_pre__container}>
                    <div className={styles.footer_pre__3cat}>
                        <Enllac href='https://www.3cat.cat/3cat/'>
                            <Image
                                loader={imageLoader}
                                unoptimized={true}
                                src={basePath + '/_resources/logos/3cat.svg?v=1.2504.2'}
                                width={97}
                                height={28}
                                className={styles.imgM}
                                loading='lazy'
                                alt='3Cat'
                            ></Image>
                        </Enllac>
                    </div>
                    <div className={styles.footer_pre__logos}>
                        <Enllac href='https://www.3cat.cat/tv3/'>
                            <Image
                                loader={imageLoader}
                                unoptimized={true}
                                src={basePath + '/_resources/logos/tv3_W.svg?v=1.2504.2'}
                                width={36}
                                height={28}
                                className={styles.imgM}
                                loading='lazy'
                                alt='TV3'
                            ></Image>
                        </Enllac>
                        <Enllac href='https://www.3cat.cat/catradio/'>
                            <Image
                                loader={imageLoader}
                                unoptimized={true}
                                src={basePath + '/_resources/logos/cr_W.svg?v=1.2504.2'}
                                width={34}
                                height={30}
                                className={styles.imgL}
                                loading='lazy'
                                alt='Catalunya Ràdio'
                            ></Image>
                        </Enllac>
                        <Enllac href='https://www.3cat.cat/tv3/sx3/'>
                            <Image
                                loader={imageLoader}
                                unoptimized={true}
                                src={basePath + '/_resources/logos/sx3_W.svg?v=1.2504.2'}
                                width={44}
                                height={22}
                                className={styles.imgS}
                                loading='lazy'
                                alt='SX3'
                            ></Image>
                        </Enllac>
                        <Enllac href='https://www.3cat.cat/3cat/eva/'>
                            <Image
                                loader={imageLoader}
                                unoptimized={true}
                                src={basePath + '/_resources/logos/eva_W.svg?v=1.2504.2'}
                                width={50}
                                height={22}
                                className={styles.imgS}
                                loading='lazy'
                                alt='EVA'
                            ></Image>
                        </Enllac>
                    </div>
                    <div className={styles.footer_pre__links}>
                        <Enllac href='https://www.3cat.cat/324/'>NOTÍCIES</Enllac>
                        <Enllac href='https://www.3cat.cat/esport3/'>ESPORTS</Enllac>
                    </div>
                </div>
            </div>

            <div className={styles.footer_main__container}>
                <div className={`${styles.footer_main__corpo} ${stylesDropdownCorpo}`}>
                    <h3 className={styles.js_desplegable} onClick={() => setOpenDropdownCorpo(!openDropdownCorpo)}>
                        <span>Corporatiu</span>
                        <span className={styles.drowpdown_icona}>
                            <Icona icona='fletxa_simple_avall' alt='desplegar' />
                        </span>
                    </h3>
                    <ul>
                        <li>
                            <Enllac href='https://www.3cat.cat/corporatiu/ca/el-grup/'>
                                <span>El grup</span>
                            </Enllac>
                        </li>
                        <li>
                            <Enllac href='https://www.3cat.cat/corporatiu/transparencia/'>
                                <span>Transparència</span>
                            </Enllac>
                        </li>
                        <li>
                            <Enllac href='https://www.3cat.cat/corporatiu/rs/'>
                                <span>Responsabilitat social</span>
                            </Enllac>
                        </li>
                        <li>
                            <Enllac href='https://www.3cat.cat/tv3/marato/'>
                                <span>Fundació La Marató</span>
                            </Enllac>
                        </li>
                        <li>
                            <Enllac href='https://www.3cat.cat/premsa/'>
                                <span>Premsa</span>
                            </Enllac>
                        </li>
                        <li>
                            <Enllac href='https://www.3cat.cat/publicitat/ca/'>
                                <span>Publicitat</span>
                            </Enllac>
                        </li>
                        <li>
                            <Enllac href='https://www.3cat.cat/tv3/sales/'>
                                <span>Vendes internacionals</span>
                            </Enllac>
                        </li>
                        <li>
                            <Enllac href='https://www.3cat.cat/presentacio-projectes/'>
                                <span>Presentació de projectes</span>
                            </Enllac>
                        </li>
                        <li>
                            <Enllac href='https://epc.3cat.cat/Proveidor/index.jsf'>
                                <span>Espai Proveïdor - Client</span>
                            </Enllac>
                        </li>
                        <li>
                            <Enllac href='https://epc.3cat.cat/Proveidor/pcontractant/presentacio.jsf'>
                                <span>Perfil del contractant</span>
                            </Enllac>
                        </li>
                        <li>
                            <Enllac href='https://www.3cat.cat/venda-imatges/'>
                                <span>Còpia i Venda d&apos;imatges</span>
                            </Enllac>
                        </li>
                        <li>
                            <Enllac href='https://botiga.3cat.cat/'>
                                <span>Botiga</span>
                            </Enllac>
                        </li>
                        <li>
                            <Enllac href='https://seleccio.3cat.cat/'>
                                <span>Selecció de personal</span>
                            </Enllac>
                        </li>
                        <li>
                            <Enllac href='https://casting.3cat.cat/'>
                                <span>Càsting</span>
                            </Enllac>
                        </li>
                    </ul>
                </div>
                <div className={`${styles.footer_main__contacte} ${stylesDropdownContact}`}>
                    <h3 className={styles.js_desplegable} onClick={() => setOpenDropdownContact(!openDropdownContact)}>
                        <span>Contacta&apos;ns</span>
                        <span className={styles.drowpdown_icona}>
                            <Icona icona='fletxa_simple_avall' alt='desplegar' />
                        </span>
                    </h3>
                    <ul>
                        <li>
                            <Enllac href='https://www.3cat.cat/atencio/'>
                                <span>Atenció a l&apos;audiència</span>
                            </Enllac>
                        </li>
                        <li>
                            <Enllac href='https://www.3cat.cat/atencio/com-veure-i-escoltar/'>
                                <span>Com veure&apos;ns i escoltar-nos</span>
                            </Enllac>
                        </li>
                        <li>
                            <Enllac href='https://www.3cat.cat/visita-les-instalacions/'>
                                <span>Visita&apos;ns</span>
                            </Enllac>
                        </li>
                    </ul>
                </div>
                <div className={`${styles.footer_main__llengua} ${stylesDropdownLlengua}`}>
                    <h3 className={styles.js_desplegable} onClick={() => setOpenDropdownLlengua(!openDropdownLlengua)}>
                        <span>Llengua</span>
                        <span className={styles.drowpdown_icona}>
                            <Icona icona='fletxa_simple_avall' alt='desplegar' />
                        </span>
                    </h3>
                    <ul>
                        <li>
                            <Enllac href='https://www.3cat.cat/angles-i-llengues/'>
                                <span>Promoció de la llengua</span>
                            </Enllac>
                        </li>
                        <li>
                            <Enllac href='https://esadir.cat/'>
                                <span>És a dir</span>
                            </Enllac>
                        </li>
                        <li>
                            <Enllac href='https://www.3cat.cat/aranes/'>
                                <span>Espai de l&apos;aranès</span>
                            </Enllac>
                        </li>
                        <li>
                            <Enllac href='https://www.3cat.cat/bon-dia/'>
                                <span>Bon dia. Directori de mitjans en llengua catalana</span>
                            </Enllac>
                        </li>
                        <li>
                            <Enllac href='https://www.3cat.cat/llibredestil/'>
                                <span>Llibre d&apos;estil</span>
                            </Enllac>
                        </li>
                    </ul>
                </div>
                <div className={styles.footer_main__troba}>
                    <h3>Troba&apos;ns a</h3>
                    <ul>
                        <li className={styles.footer_main__boto}>
                            <Enllac href='https://www.3cat.cat/atencio/com-veure-i-escoltar/mobils-i-tauletes/'>
                                <div className={styles.footer_main__ico__smartphone}>
                                    <div className={styles.footer_main__ico}>
                                        <Image
                                            loader={imageLoader}
                                            unoptimized={true}
                                            src='https://statics.3cat.cat/recursos/3cat/icones/smartphone.svg'
                                            loading='lazy'
                                            width={24}
                                            height={24}
                                            alt='icona mòbils'
                                            aria-hidden='true'
                                        ></Image>
                                    </div>
                                </div>
                                <div>Mòbils i tauletes</div>
                            </Enllac>
                        </li>
                        <li className={styles.footer_main__boto}>
                            <Enllac href='https://www.3cat.cat/atencio/com-veure-i-escoltar/televisors-connectats/'>
                                <div className={styles.footer_main__ico__smarttv}>
                                    <div className={styles.footer_main__ico}>
                                        <Image
                                            loader={imageLoader}
                                            unoptimized={true}
                                            src='https://statics.3cat.cat/recursos/3cat/icones/smarttv.svg'
                                            loading='lazy'
                                            width={26}
                                            height={20}
                                            alt='icona televisió connectada'
                                            aria-hidden='true'
                                        ></Image>
                                    </div>
                                </div>
                                <div>Televisions connectades</div>
                            </Enllac>
                        </li>
                        <li className={styles.footer_main__boto}>
                            <Enllac href='https://statics.3cat.cat/recursos/3cat/ajuda/' className={styles.footer_main__boto_ajuda}>
                                <div className={styles.footer_main__ico__smarttv}>
                                    <div className={styles.footer_main__ico}>
                                        <Image
                                            loader={imageLoader}
                                            unoptimized={true}
                                            src='https://statics.3cat.cat/recursos/3cat/icones/ic_info_contorn.svg'
                                            loading='lazy'
                                            width={26}
                                            height={26}
                                            alt='icona televisió connectada'
                                            aria-hidden='true'
                                        ></Image>
                                    </div>
                                </div>
                                <div>Ajuda plataforma 3Cat</div>
                            </Enllac>
                        </li>
                    </ul>
                </div>
            </div>

            <div className={styles.footer_post__container}>
                <div>
                    <Enllac href='https://www.3cat.cat/avis-legal/'>
                        <span>Avís legal</span>
                    </Enllac>
                    <Enllac href='https://www.3cat.cat/canal-etic/'>
                        <span>Canal Ètic</span>
                    </Enllac>
                    <Enllac href='https://www.3cat.cat/avis-legal/cookies/'>
                        <span>Cookies</span>
                    </Enllac>
                    <Enllac href='https://www.3cat.cat/avis-legal/privacitat/delegat-de-proteccio-de-dades/'>
                        <span>Delegat de protecció de dades</span>
                    </Enllac>
                    <Enllac href='https://www.3cat.cat/atencio/accessibilitat/'>
                        <span>Accessibilitat</span>
                    </Enllac>
                    <Enllac href='https://www.3cat.cat/atencio/mapaweb/'>
                        <span>Mapa web</span>
                    </Enllac>
                </div>
                <div className={styles.footer_post__copy}>© 3Cat</div>
            </div>
        </footer>
    );
}
